// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Next Import
import { Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// ** Axios
import axios from 'axios'

// ** Config
import authConfig from '../configs/auth'
import FallbackSpinner from 'src/@core/components/spinner'
import { ApiEndpoints } from 'src/network/endpoints'
import { axiosInstance } from 'src/network/adapter'
import toast from 'react-hot-toast'
import { ApiOff } from 'mdi-material-ui'

// ** Defaults
const defaultProvider = {
  user: null,
  // token: null,
  mpin: false,
  loading: true,
  otpVerifyData: null,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  signup: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  // const [token, setToken] = useState(defaultProvider.token)
  const [mpin, setMpin] = useState(defaultProvider.mpin)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)
  const [otpVerifyData, setOtpVerifyData] = useState(null)
  const [previousLink, setPreviousLink] = useState('/home')

  // ** Hooks
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      const storedToken = window.sessionStorage.getItem(authConfig.storageTokenKeyName)
      const storedUser = window.localStorage.getItem(authConfig.storageUserDataKeyName)
      // if (storedToken && storedUser) {
      //   navigate('/check-mpin', {
      //     state: {
      //       user: storedUser
      //     }
      //   })
      // } else
      if (storedToken) {
        setLoading(true)
        await axiosInstance
          .get(ApiEndpoints.AUTH.me, {
            headers: {
              Authorization: storedToken
            }
          })
          .then(async response => {
            setLoading(false)
            let user = { ...response.data.data.user }
            if (user.mpin_created) {
              setUser({ ...user })
              window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(user))
            } else {
              navigate('/create-mpin', {
                state: {
                  token: response.data.data.token,
                  type: 'create'
                }
              })
            }
          })
          .catch(() => {
            localStorage.removeItem(authConfig.storageUserDataKeyName)
            sessionStorage.removeItem(authConfig.storageTokenKeyName)
            setUser(null)
            setLoading(false)
          })
      } else if (storedUser) {
        setLoading(false)
        setPreviousLink(location.pathname)
        navigate('/check-mpin', {
          state: {
            user: storedUser
          }
        })
      } else {
        setLoading(false)
      }
    }
    initAuth()
  }, [])

  const handleLogin = response => {
    // login({ token: response.data.data.token, user: response.data.data.user }, err => {

    const user = response.data.data.user
    if (user.verify_account) {
      if (user.mpin_created) {
        window.sessionStorage.setItem(authConfig.storageTokenKeyName, response.data.data.token)
        window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(user))
        setUser({ ...user })
        navigate('/home')
      } else {
        navigate('/create-mpin', {
          state: {
            token: response.data.data.token,
            type: 'create'
          }
        })
      }
      // navigate('/create-mpin', {
      //   state: {
      //     token: response.data.data.token
      //   }
      // })
    } else {
      navigate('/verify-otp', {
        state: {
          otpState: response.data.data.otp,
          country_code: user.country_code,
          phone_number: user.phone_number,
          type: 'login'
        }
      })
    }
  }

  const handleLogout = () => {
    axiosInstance
      .post(ApiEndpoints.AUTH.logout)
      .then(response => {
        setUser(null)
        setIsInitialized(false)
        window.localStorage.removeItem(authConfig.storageUserDataKeyName)
        window.sessionStorage.removeItem(authConfig.storageTokenKeyName)
        navigate('/login')
      })
      .catch(error => console.log(error))
  }

  const handleSignUp = () => {}

  const initVerifyOTP = data => {
    setOtpVerifyData(data)
    navigate('/verify-otp')
  }

  const handleVerifyOtp = (type, params) => {
    axiosInstance
      .post(ApiEndpoints.OTP.verifyotp(type), params)
      .then(response => {
        toast.success(response.data.message)

        if (type === 'register') {
          // sessionStorage.setItem(authConfig.storageTokenKeyName, response.data.data.token)
          navigate('/create-mpin', {
            state: {
              token: response.data.data.token,
              type: 'create'
            }
          })
        } else if (type === 'forgot_mpin') {
          navigate('/create-mpin', {
            state: {
              token: response.data.data.token,
              type: 'reset'
            }
          })
        } else {
          // localStorage.setItem(authConfig.storageTokenKeyName, response.data.data.token)
          let user = { ...response.data.data.user }
          if (user.mpin_created) {
            sessionStorage.setItem(authConfig.storageTokenKeyName, response.data.data.token)
            window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(user))
            setUser({ ...user })
            navigate('/home')
          } else {
            navigate('/create-mpin', {
              state: {
                token: response.data.data.token,
                type: 'create'
              }
            })
          }
        }
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const setValidUser = ({ token, user }) => {
    // sessionStorage.setItem(authConfig.storageTokenKeyName, token)
    // let user = { ...response.data.data.user }
    sessionStorage.setItem(authConfig.storageTokenKeyName, token)
    window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(user))
    setUser({ ...user })
    // setMpin()
    // navigate(previousLink === ' /' || previousLink === '' || previousLink === '/' ? '/home' : previousLink)
    navigate('/home')
  }

  const values = {
    user,
    // token,
    mpin,
    loading,
    otpVerifyData,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    verifyOtp: handleVerifyOtp,
    setValidUser: setValidUser,
    logout: handleLogout,
    signup: handleSignUp,
    initVerifyOTP: initVerifyOTP
  }

  return <AuthContext.Provider value={values}>{loading ? <FallbackSpinner /> : children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
